@import
  "minima/skins/classic",
  "minima/initialize";

.social-media-list {
    list-style: none;
    margin: 0;
 }

 ul .bibliography {
  font-weight: bold;
 }
 ul .bibliography li {
  font-weight: normal;
 }